import {useEffect, useRef} from "react";

const Output = ({videoId, start, end, setVideoLength}: { videoId: string, start: number, end: number, setVideoLength: (l: number) => void }) => {
    const frameRef = useRef<HTMLDivElement | null>(null);
    const width = window.innerWidth;
    useEffect(() => {
        if (frameRef.current == null) {
            return;
        }
        const player = new window.YT.Player(
            frameRef.current!,
            {
                height: width / 16 * 9,
                width: width,
                videoId: videoId,
                events: {
                    "onReady": () => {
                        player.seekTo(start, true);
                        setVideoLength(player.getDuration());
                        player.playVideo();
                    },
                    "onStateChange": (event: any) => {
                        if (event.data === window.YT.PlayerState.PLAYING) {
                            const duration = end - start;
                            setTimeout(() => player.seekTo(start, true), duration * 1000);
                        }
                    }
                }
            }
        );
    }, [start, end, videoId, frameRef, setVideoLength]);

    return (
        <div>
            <div ref={frameRef}/>
        </div>
    );
};

export default Output;
