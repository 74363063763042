import {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Output from "./Output";


const Main = () => {
    const [videoLength, setVideoLength] = useState(0);

    const [isReady, setIsReady] = useState(false);

    const {videoId} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const start = parseInt(searchParams.get("start")!);
    const end = parseInt(searchParams.get("end")!);

    useEffect(() => {
        (window.YT as any).ready(() => {
            setIsReady(true);
        });
    }, []);

    return (
        <div>
            {isReady && videoId != null && <Output videoId={videoId!} start={start} end={end} setVideoLength={setVideoLength}/>}
        </div>
    );
};

const VideoPicker = () => {
    const [id, setId] = useState<string>("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const onSubmit = () => {
        navigate(`/${id}?start=${start}&end=${end}`);
    };

    useEffect(() => {
        const url = searchParams.get("url");
        if (url != null) {
            const parts = url.split("v=");
            const last = parts[parts.length - 1];
            setId(last);
        }
    }, [searchParams]);

    return (
        <div style={{padding: 16}}>
            <h1>YouTube Loop</h1>
            <h3>Video ID</h3>
            <input value={id} onChange={e => setId(e.target.value)}/>
            <h3>Start time seconds</h3>
            <input value={start} onChange={e => setStart(e.target.value)} type="number"/>
            <h3>End time seconds</h3>
            <input value={end} onChange={e => setEnd(e.target.value)} type="number"/>
            <br/><br/>
            <button onClick={onSubmit}>Go</button>
        </div>
    );
};

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:videoId" element={<Main/>}/>
                <Route path="/" element={<VideoPicker/>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
